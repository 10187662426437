import React, { Component } from 'react';
import { ValidationError } from '../../components'; 

class ExpandingText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '', // Inicializa com o valor das props ou vazio
      error: null,              // Estado para armazenar mensagens de erro de validação
    };
    this.timeoutId = null;
    this.input = null;
    this.update = this.update.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    // Verificando se as funções de máscara e validação foram passadas como props
    console.log("ExpandingText - maskFunction:", props.maskFunction);
    console.log("ExpandingText - validateFunction:", props.validateFunction);
  }

  update(newValue) {
    this.setState({ value: newValue });
  }

  handleChange(event) {
    const rawValue = event.target.value;
    const { maskFunction, onChange } = this.props;

    // Verificando se a função de máscara está sendo chamada
    if (maskFunction) {
      console.log("ExpandingText - Aplicando maskFunction");
    }

    // Aplica a função de máscara se fornecida
    const maskedValue = maskFunction ? maskFunction(rawValue) : rawValue;

    // Atualiza o estado com o valor mascarado
    this.setState({ value: maskedValue, error: null });

    // Executa o onChange do componente pai, se fornecido
    if (onChange) {
      onChange(maskedValue);
    }
  }

  handleBlur() {
    const { validateFunction, onBlur, input   } = this.props;
    const { value } = this.state;

    // Define o campo como "tocado"
    this.setState({ touched: true });

    // Aplica a função de validação, se fornecida
    let error = null;
    if (validateFunction) {
      error = validateFunction(value); // A função deve retornar uma mensagem de erro ou `null`
      console.log("ExpandingText - Resultado da validateFunction:", error);
    }

    // Atualiza o estado com o erro (se houver)
    this.setState({ error });

    // Executa o onBlur do componente pai, se fornecido
    if (onBlur) {
      onBlur(value, error);
    } 

    input.onBlur(value); // Atualiza o estado `touched` no react-final-form
    if (error) {
      input.onChange(value); // Passa o erro ao `meta.error` do react-final-form
    }

  }

  render() {
    const { value, error, touched } = this.state;
    console.log("This.state:", this.state);
    // Extraindo onChange e onBlur para evitar sobrescrita
    const { onChange, onBlur, maskFunction, validateFunction, ...otherProps } = this.props;

    return (
      <div>
        <input
          {...otherProps}       // Passa outras props, como `type`, `placeholder`
          value={value}         // Controla o valor pelo estado interno
          onChange={this.handleChange} // Aplica máscara ao modificar o valor
          onBlur={this.handleBlur}     // Valida o valor ao sair do campo
          ref={input => {
            this.input = input;
          }}
        />    
         {/* Exibe ValidationError se o campo foi tocado e há um erro */}
         <ValidationError
          fieldMeta={{ touched, error }}
          className="errorClass" // Estilo opcional
        />   
      </div>
    );
  }
}

export default ExpandingText;
